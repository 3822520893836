import { Component } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

class AppToasts extends Component {

    render() {
        return <div
            aria-live="polite"
            aria-atomic="true"
            className="fixed-bottom"
            style={{ minHeight: '240px', pointerEvents: 'none', zIndex: 99999 }}
        >
            <ToastContainer position="bottom-end" className="m-2">

                {this.props.toasts.map((toast, key) =>
                    <Toast key={key} onClose={() => this.props.onClose(toast)} bg="warning" delay={toast.delay ?? 15000} autohide={toast.autohide ?? true}>
                        <Toast.Header className="bg-warning" closeButton><div className='me-auto text-light'><b>{toast.header}</b></div></Toast.Header>
                        <Toast.Body className="text-light">{toast.body}</Toast.Body>
                    </Toast>
                )}
            </ToastContainer>
        </div>;
    }
}

export default AppToasts;