import { Component } from "react";
import { Container } from "react-bootstrap";
import backgroundImage from '../images/background.jpg';
import logoImage from '../images/cedec-cbmpa.png';

class Home extends Component {

    render() {
        return <Container fluid className="background-image text-black" style={{backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${backgroundImage})`}}>
            <div className="text-center pt-3"><img src={logoImage} width={200}/></div>
            <h3>Bem-vindo ao GUARD</h3><hr />
            <p align="justify">O GUARD (Gestão Unificada de Avaliação de Riscos e Desastres) é o sistema oficial da Coordenadoria Estadual de Defesa Civil destinado ao gerenciamento de pedidos de reconhecimento de situação de emergência e calamidade pública. Desenvolvido para modernizar e agilizar os processos da Defesa Civil, o sistema promove eficiência, transparência e integração entre as coordenadorias estadual e municipais.</p>

            <p align="justify">Com o GUARD, gestores e técnicos têm acesso a ferramentas avançadas para a submissão, análise e acompanhamento de relatórios e documentos essenciais ao reconhecimento de desastres e solicitação de ajuda humanitária. Essa plataforma reforça o compromisso do estado em proteger vidas, reduzir impactos e fomentar a reconstrução das comunidades afetadas.</p>

            <h3 className="mt-3">Uma Solução Integrada e Colaborativa</h3><hr />
            <p align="justify">O sistema promove a colaboração interinstitucional, conectando gestores de diferentes níveis para uma resposta coordenada e eficaz. O uso do GUARD fortalece a comunicação e a tomada de decisões baseadas em dados precisos e atualizados, refletindo o compromisso com a gestão humanitária e a eficiência pública.</p>

            <h3 className="mt-3">Processos Ágeis e Seguros</h3><hr />
            <p align="justify">A plataforma automatiza etapas cruciais dos processos de homologação e reconhecimento, reduzindo a burocracia e acelerando a resposta às emergências. Com o GUARD, relatórios e documentos podem ser submetidos e avaliados em tempo real, garantindo que o apoio estadual chegue de forma rápida e assertiva aos municípios que mais precisam.</p>

            <h3 className="mt-3">Foco na Ajuda Humanitária e Reconstrução</h3><hr />
            <p align="justify">Além de agilizar os processos formais, o GUARD apoia as ações de ajuda humanitária e reconstrução, garantindo que os municípios afetados por desastres naturais ou emergências recebam suporte adequado. O sistema contribui para o planejamento estratégico e a alocação eficiente de recursos, promovendo o restabelecimento rápido das comunidades impactadas.</p>

            <p align="justify">Com um banco de dados centralizado, o GUARD oferece relatórios consolidados que auxiliam no monitoramento de ocorrências e no planejamento preventivo, reforçando a resiliência das regiões mais vulneráveis.</p>

            <h3 className="mt-3">Acesse Agora</h3><hr />
            <p align="justify">Se você é um gestor ou técnico da Defesa Civil, utilize o GUARD para transformar a gestão de emergências no seu município. Faça login para explorar todas as funcionalidades do sistema e contribuir com uma resposta ágil e eficiente às demandas humanitárias.</p>

            <p align="justify" className="mb-0">Juntos, podemos fortalecer as comunidades e promover um futuro mais seguro para todos.</p>

        </Container>
    }

}

export default Home;

