import { Component } from "react";
import AppContext from "../context/AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class QuestionarioForm extends Component {

    static contextType = AppContext;

    state = {
        ...this.props.data
    }

    submit(event) {
        event.preventDefault();
        this.props.onSave(this.state);
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.submit(e)}>
                <Row>
                <Col lg={12} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Pergunta</b></Form.Label>
                            <Form.Control required as="textarea" value={this.state.pergunta} onChange={e => this.setState({ pergunta: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Divisão</b></Form.Label>
                            <Form.Control required value={this.state.divisao} onChange={e => this.setState({ divisao: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Tipo</b></Form.Label>
                            <Form.Select required value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })}>
                                <option></option>
                                <option>Sim/Não</option>
                                <option>Inteiro</option>
                                <option>Decimal</option>
                                <option>Moeda</option>
                                <option>Percentual</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={12} className="d-flex justify-content-end mt-2">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        <Button variant="success" className="ms-2" type="submit"><FontAwesomeIcon icon={faCheck} /> Gravar</Button>
                    </Col>
                </Row>
            </Form>
        </Container >;
    }
}

export default QuestionarioForm;
