import { Component } from "react";
import AppContext from "../context/AppContext";
import { Button, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faTimes } from "@fortawesome/free-solid-svg-icons";
import MapPolygonEditor from "../components/MapPolygonEditor";
import MapCoordinateEditor from "../components/MapCoordinateEditor";
import ImageEditor from "../components/ImageEditor";
import moment from 'moment';
import logo from '../images/brasao-para.png';
import QRCode from 'qrcode';

class FormularioPrint extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.data,

            decreto_data: moment(this.props.data.decreto_data).format("DD/MM/YYYY"),
            datahora_ocorrencia: moment(this.props.data.datahora_ocorrencia).format("DD/MM/YYYY HH:mm"),
            datahora_solicitacao: moment(this.props.data.datahora_solicitacao).format("DD/MM/YYYY HH:mm"),
            datahora_alteracao: moment(this.props.data.datahora_alteracao).format("DD/MM/YYYY HH:mm"),
            datahora_reconhecimento: this.props.data.datahora_reconhecimento ? moment(this.props.data.datahora_reconhecimento).format("DD/MM/YYYY HH:mm") : "Não registrado",
            datahora_autorizacao: this.props.data.datahora_autorizacao ? moment(this.props.data.datahora_autorizacao).format("DD/MM/YYYY HH:mm") : "Não registrado",

        }

    }

    componentDidMount() {
        this.state.Fotografias.forEach((fotografia) => {
            this.context.fetchApi("GET", `/fotografias/${fotografia.id}/arquivo`, null, { responseType: 'blob' })
                .then(({ data }) => {
                    const arquivo = new File([data], "");
                    this.setState(({ Fotografias }) => ({
                        Fotografias: Fotografias.map(f => f === fotografia ? {
                            ...fotografia,
                            datahora: moment(fotografia.datahora).format("DD/MM/YYYY HH:mm"),
                            arquivo
                        } : f)
                    }));
                });
        });

        this.state.Anexos.forEach(anexo => {
            QRCode.toDataURL(process.env.REACT_APP_BACKEND_URL + `/anexo/${anexo.uuid}/download`, { margin: 0, width: 100 }, (err, url) => {
                if (err) {
                    this.context.addToast({ header: "Erro na geração do QRCode", body: err.message })
                } else {
                    this.setState(state => ({
                        Anexos: state.Anexos.map(a => a === anexo ? { ...a, url } : a)
                    }));
                }
            })
        })
    }

    print() {
        window.print();
    }

    submit(e) {
        e.preventDefault();
        this.print();
    }


    getSiblingQuestionario(questionario, divisao) {
        return this.state.Questionarios.find(q => q.pergunta === questionario.pergunta && q.divisao === divisao) ?? {};
    }

    render() {

        const numberFormatter = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 4 });
        const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
        const formatNumber = number => numberFormatter.format(number);
        const formatCurrency = currency => currencyFormatter.format(currency);

        return <Container fluid="lg" className="paper-print">
            <Form onSubmit={e => this.submit(e)}>
                <div className="text-center" style={{ lineHeight: "1rem" }}>
                    <img src={logo} style={{ width: "1.5cm" }} alt="CEDEC-CBMPA" />
                    <br />
                    <b>
                        Governo do Estado do Pará<br />
                        Corpo de Bombeiros Militar do Pará<br />
                        Coordenadoria Estadual de Defesa Civil<br />
                        Formulário de Solicitação de Ajuda Humanitária
                    </b>
                </div>
                <hr />
                <h4>1. Identificação</h4>
                <Row>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nº do Formulário</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.id?.toString().padStart(6, '0') ?? "(Em Edição)"} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Status da Solicitação</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.status} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Protocolo S2ID</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.protocolo_s2id} />
                        </Form.Group>
                    </Col>
                    <Col sm={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Município</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.Municipio?.nome ?? "Não Indicado"} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Desastre</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.Cobrade?.nome ?? "Não Indicado"} />
                        </Form.Group>
                    </Col>
                    <Col sm={3} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data/Hora da Ocorrência</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.datahora_ocorrencia} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nº do Decreto Municipal</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.decreto_numero} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Data do Decreto</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.decreto_data} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Tipo de Decreto</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.decreto_tipo} />
                        </Form.Group>
                    </Col>
                </Row>
                <h4 className="mt-5">2. Área Afetada</h4>
                <Row>
                    <Col sm={6} className="mb-1">
                        <h5 className="mt-3">2.1 Identificação das Áreas Afetadas</h5>
                        <Table size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th className="text-center">Área</th>
                                    <th className="text-center" style={{ width: 120 }}>Urbana</th>
                                    <th className="text-center" style={{ width: 120 }}>Rural</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Área Afetada Urbana").map((questionario, key) => {
                                    const questionarioRural = this.getSiblingQuestionario(questionario, "Área Afetada Rural");
                                    return <tr key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Check disabled checked={questionario.FormularioQuestionario.resposta === "Sim"} />
                                        </td>
                                        <td className="text-center">
                                            <Form.Check disabled checked={questionarioRural.FormularioQuestionario.resposta === "Sim"} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                    <Col sm={6} className="mb-1 d-flex flex-column">
                        <h5 className="mt-3">2.2 Mapa da Área Afetada</h5>
                        <div className="flex-grow-1" style={{ minHeight: 500 }}>
                            <MapPolygonEditor print coordinates={this.state.Coordenadas} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">2.3 Descrição da Área Afetada</h5>
                        <p>{this.state.area_descricao? this.state.area_descricao : "(Sem informações)"}</p>
                    </Col>
                </Row>
                <h4 className="mt-5">3. Causas e Efeitos do Desastres</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <p>{this.state.causa_descricao? this.state.causa_descricao : "(Sem informações)"}</p>
                    </Col>
                </Row>
                <h4 className="mt-5">4. Danos Humanos, Materiais e Ambientais</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">4.1 Danos Humanos</h5>
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Discriminação</th>
                                    <th className="text-center" style={{ width: 120 }}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Humanos").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Humanos").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseInt(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>4.1.1 Descrição dos Danos Humanos</h6>
                        <p>{this.state.dh_descricao? this.state.dh_descricao : "(Sem informações)"}</p>
                    </Col>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">4.2 Danos Materiais</h5>
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Discriminação</th>
                                    <th className="text-center" style={{ width: 120 }}>Danificados</th>
                                    <th className="text-center" style={{ width: 120 }}>Destruídos</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Prejuízo em R$</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Danificados)").map((questionario, key) => {
                                    const questionarioDestruido = this.getSiblingQuestionario(questionario, "Danos Materiais (Destruídos)");
                                    const questionarioPrejuizo = this.getSiblingQuestionario(questionario, "Danos Materiais (Prejuízos)");
                                    return <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td>
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                        <td>
                                            <Form.Control size="sm" disabled value={questionarioDestruido.FormularioQuestionario.resposta} />
                                        </td>
                                        <td>
                                            <Form.Control size="sm" disabled value={formatCurrency(questionarioPrejuizo.FormularioQuestionario.resposta)} />
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Danificados)").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseInt(questionario.FormularioQuestionario.resposta) : 0), 0)}
                                    </th>
                                    <th>
                                        {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Destruídos)").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseInt(questionario.FormularioQuestionario.resposta) : 0), 0)}
                                    </th>
                                    <th>
                                        {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Materiais (Prejuízos)").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseFloat(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>4.2.1 Descrição dos Danos Materiais</h6>
                        <p>{this.state.dm_descricao? this.state.dm_descricao : "(Sem informações)"}</p>
                    </Col>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">4.3 Danos Ambientais</h5>
                        <Table size="sm" className="mb-0">
                            <thead>
                                <tr>
                                    <th className="text-center">Dano Ambiental</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>% População Atingida</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (População Atingida)").map((questionario, key) => {
                                    return <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Incêndios</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Área Atingida (em hectares)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (Incêndios)").map((questionario, key) => {
                                    return <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Danos Ambientais (Incêndios)").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseFloat(questionario.FormularioQuestionario.resposta) : 0), 0))} ha
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>4.3.1 Descrição dos Danos Ambientais</h6>
                        <p>{this.state.da_descricao? this.state.da_descricao : "(Sem informações)"}</p>
                    </Col>
                </Row>
                <h4 className="mt-5">5. Prejuízos Econômicos Públicos e Privados</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">5.1 Prejuízos Econômicos Públicos</h5>
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Serviço Essencial Prejudicado</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Valor do Prejuízo (em R$)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Públicos").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Públicos").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseFloat(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>5.1.1 Descrição dos Prejuízos Econômicos Públicos</h6>
                        <p>{this.state.pu_descricao? this.state.pu_descricao : "(Sem informações)"}</p>
                    </Col>
                    <Col sm={12} className="mb-1">
                        <h5 className="mt-3">5.2 Prejuízos Econômicos Privados</h5>
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Setor Econômico</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Valor do Prejuízo (em R$)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Privados").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Prejuízos Econômicos Privados").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseFloat(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6>5.2.1 Descrição dos Prejuízos Econômicos Privados</h6>
                        <p>{this.state.pr_descricao? this.state.pr_descricao : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h4 className="mt-5">6. Caracterização de Situação de Emergência ou Calamidade Pública</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Característica</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Caracterização").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center text-nowrap">
                                            <Form.Check disabled inline name={`Caracterização-${key}`} value="Sim" type="radio" checked={questionario.FormularioQuestionario.resposta === "Sim"} label="Sim" />
                                            <Form.Check disabled inline name={`Caracterização-${key}`} value="Não" type="radio" checked={questionario.FormularioQuestionario.resposta === "Não"} label="Não" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <h5  className="mt-3">6.1 Resumo dos Danos e Prejuízos</h5>
                        <p>{this.state.declaracao_danos? this.state.declaracao_danos : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h4 className="mt-5">7. Informações Relevantes</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Histórico</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Histórico").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center text-nowrap">
                                            <Form.Check disabled inline name={`Histórico-${key}`} value="Sim" type="radio" checked={questionario.FormularioQuestionario.resposta === "Sim"} label="Sim" />
                                            <Form.Check disabled inline name={`Histórico-${key}`} value="Não" type="radio" checked={questionario.FormularioQuestionario.resposta === "Não"} label="Não" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <h5  className="mt-3">7.1 Ações Preventivas e Atuação Emergencial</h5>
                        <p>{this.state.declaracao_emergencial? this.state.declaracao_emergencial : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h4 className="mt-5">8. Informações sobre a Capacidade Gerencial do Município</h4>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Planejamento Estratégico</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Sim / Não</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Planejamento Estratégico").map((questionario, key) =>
                                    <tr role="button" key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center text-nowrap">
                                            <Form.Check disabled inline name={`Estratégico-${key}`} value="Sim" type="radio" checked={questionario.FormularioQuestionario.resposta === "Sim"} label="Sim" />
                                            <Form.Check disabled inline name={`Estratégico-${key}`} value="Não" type="radio" checked={questionario.FormularioQuestionario.resposta === "Não"} label="Não" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <h5  className="mt-3">8.1 Dificuldades na Gestão do Desastre</h5>
                        <p>{this.state.declaracao_dificuldades? this.state.declaracao_dificuldades : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h4 className="mt-5">9. Medidas e Ações em Curso</h4>

                <h5 className="mt-3">9.1 Mobilização e Emprego de Recursos Humanos e Institucionais</h5>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Pessoal Empregado</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização de Pessoal").map((questionario, key) =>
                                    <tr key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatNumber(this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização de Pessoal").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseInt(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6  className="mt-3">9.1.1 Descrição do Pessoal Empregado</h6>
                        <p>{this.state.declaracao_mobilizacao_pessoal? this.state.declaracao_mobilizacao_pessoal : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h5 className="mt-3">9.2 Mobilização e Emprego de Recursos Materiais</h5>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Material/Equipamento Empregado</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização de Materiais").map((questionario, key) =>
                                    <tr key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={questionario.FormularioQuestionario.resposta} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <h6  className="mt-3">9.2.1 Descrição do Material Empregado</h6>
                        <p>{this.state.declaracao_mobilizacao_material? this.state.declaracao_mobilizacao_material : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h5 className="mt-3">9.3 Mobilização e Emprego de Recursos Financeiros</h5>
                <Row>
                    <Col sm={12} className="mb-1">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th className="text-center">Recurso Empregado</th>
                                    <th className="text-center text-nowrap" style={{ width: 120 }}>Valor em R$</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização Financeira").map((questionario, key) =>
                                    <tr key={key}>
                                        <td>{questionario.pergunta}</td>
                                        <td className="text-center">
                                            <Form.Control size="sm" disabled value={formatCurrency(questionario.FormularioQuestionario.resposta)} />
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>Total</th>
                                    <th>
                                        {formatCurrency(this.state.Questionarios.filter(({ divisao }) => divisao === "Mobilização Financeira").reduce((total, questionario) => total + (questionario.FormularioQuestionario.resposta ? parseFloat(questionario.FormularioQuestionario.resposta) : 0), 0))}
                                    </th>
                                </tr>
                            </tbody>
                        </Table>
                        <h6  className="mt-3">9.3.1 Descrição dos Recursos Financeiros Empregados</h6>
                        <p>{this.state.declaracao_mobilizacao_financeiro? this.state.declaracao_mobilizacao_financeiro : "(Sem informações)"}</p>
                    </Col>
                </Row>

                <h4 className="mt-5">10. Relatório Fotográfico</h4>
                {this.state.Fotografias.length? this.state.Fotografias.map((fotografia, key) =>
                    <Card key={key} className="mb-2 fotografia-container">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            Situação {(key + 1).toString().padStart(2, '0')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={5} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Título</b></Form.Label>
                                        <Form.Control size="sm" disabled value={fotografia.titulo} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Data/Hora</b></Form.Label>
                                        <Form.Control size="sm" disabled value={fotografia.datahora} />
                                    </Form.Group>
                                </Col>
                                <Col sm={4} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Latitude / Longitude</b></Form.Label>
                                        <InputGroup>
                                            <Form.Control size="sm" disabled value={fotografia.latitude} />
                                            <Form.Control size="sm" disabled value={fotografia.longitude} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col sm={6} style={{ height: 500 }}>
                                    <ImageEditor file={fotografia.arquivo} />
                                </Col>
                                <Col sm={6} style={{ height: 500 }}>
                                    <MapCoordinateEditor print coordinate={fotografia} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className="mb-1">
                                    <Form.Label><b>Descrição da Situação</b></Form.Label>
                                    <p>{fotografia.descricao? fotografia.descricao : "(Sem Informações)"}</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ): "(Sem Informações)"}
                <h4 className="mt-5">11. Plano de Trabalho</h4>
                {this.state.Pedidos.length? this.state.Pedidos.map((pedido, key) =>
                    <Card key={key} className="mb-2 pedido-container">
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            Localidade {(key + 1).toString().padStart(2, '0')}
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Localidade</b></Form.Label>
                                        <Form.Control size="sm" disabled value={pedido.localidade} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <Form.Group>
                                        <Form.Label><b>Latitude / Longitude</b></Form.Label>
                                        <InputGroup>
                                            <Form.Control size="sm" disabled value={pedido.latitude} />
                                            <Form.Control size="sm" disabled value={pedido.longitude} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="d-flex align-items-stretch">
                                <Col sm={12} className="mb-1 d-flex flex-column" style={{ minHeight: 500 }}>
                                    <div className="flex-grow-1">
                                        <MapCoordinateEditor print coordinate={pedido} />
                                    </div>
                                </Col>
                                <Col sm={12} className="mb-1">
                                    <Form.Group>
                                        <Table size="sm" className="mb-0">
                                            <thead>
                                                <tr className="text-small">
                                                    <th>Material</th>
                                                    <th className="text-center" style={{ width: 120 }}>Pessoas Desabrigadas</th>
                                                    <th className="text-center" style={{ width: 120 }}>Pessoas Desalojadas</th>
                                                    <th className="text-center" style={{ width: 120 }}>Período (dias)</th>
                                                    <th className="text-center" style={{ width: 120 }}>Quantidade Solicitada</th>
                                                    <th className="text-center" style={{ width: 120 }}>Quantidade Autorizada</th>
                                                    <th className="text-center" style={{ width: 120 }}>Quantidade Entregue</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pedido.Materiais.map((material, key) =>
                                                    <tr key={key}>
                                                        <td>{material.nome}</td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.desabrigados} /></td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.desalojados} /></td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.periodo} /></td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.qtd_solicitada} /></td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.qtd_autorizada} /></td>
                                                        <td><Form.Control size="sm" disabled value={material.PedidoMaterial.qtd_entregue} /></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ): "(Sem Informações)"}
                <h4 className="mt-5">12. Anexos</h4>
                <Table size="sm">
                    <tbody>
                        {this.state.Anexos.map((anexo, key) =>
                            <tr key={key}>
                                <td>
                                    <Form.Group>
                                        <Form.Label><b>Descrição:</b></Form.Label> {anexo.descricao}<br />
                                        <Form.Label><b>Arquivo:</b></Form.Label> {anexo.nome}
                                    </Form.Group>
                                </td>
                                <td className="text-center" style={{ width: "1%" }}>
                                    <img src={anexo.url} className="m-2" />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <h4 className="mt-5">13. Responsável pelo Preenchimento</h4>
                <Row className="mb-5">
                    <Col sm={8} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Nome</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.responsavel_nome} />
                        </Form.Group>
                    </Col>
                    <Col sm={4} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Cargo</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.responsavel_cargo} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Email</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.responsavel_email} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-1">
                        <Form.Group>
                            <Form.Label><b>Contato</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.responsavel_contato} />
                        </Form.Group>
                    </Col>
                </Row>
                <h4 className="mt-5">14. Histórico da Solicitação</h4>
                <Row>
                    <Col sm={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Data/Hora da Solicitação</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.datahora_solicitacao} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Última Alteração</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.datahora_alteracao} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Data/Hora do Reconhecimento</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.datahora_reconhecimento} />
                        </Form.Group>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <Form.Group>
                            <Form.Label><b>Data/Hora da Autorização</b></Form.Label>
                            <Form.Control size="sm" disabled value={this.state.datahora_autorizacao} />
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex justify-content-end d-print-none">
                    <div>
                        <Button variant="secondary" onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faTimes} /> Voltar</Button>
                        <Button variant="success" className="ms-2" type="submit"><FontAwesomeIcon icon={faPrint} /> Imprimir</Button>
                    </div>
                </div>
            </Form>
        </Container >;
    }
}

export default FormularioPrint;
