import React, { Component } from 'react';
import { Form, Button, Row, Col, Container, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppContext from '../context/AppContext';
import { faBox, faBoxOpen, faBullhorn, faCogs, faQuestion, faUsers } from '@fortawesome/free-solid-svg-icons';
import CobradeList from './CobradeList';
import QuestionarioList from './QuestionarioList';
import MaterialList from './MaterialList';
import ColaboradorList from './ColaboradorList';

class AdminPage extends Component {

    static contextType = AppContext;

 
    render() {
        return (
            <Container>
                <h3 className='mt-3'><FontAwesomeIcon icon={faCogs} /> Administração</h3>
                <hr />
                <Row>
                    <Col className='mb-3'>
                        <Card role="button" onClick={() => this.context.setContent(<CobradeList />)}>
                            <Card.Body className='text-center'><FontAwesomeIcon icon={faBullhorn} size="4x" /><br />COBRADE</Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-3'>
                        <Card role="button" onClick={() => this.context.setContent(<QuestionarioList />)}>
                            <Card.Body className='text-center'><FontAwesomeIcon icon={faQuestion} size="4x" /><br />Questionário</Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-3'>
                        <Card role="button" onClick={() => this.context.setContent(<MaterialList />)}>
                            <Card.Body className='text-center'><FontAwesomeIcon icon={faBox} size="4x" /><br />Materiais</Card.Body>
                        </Card>
                    </Col>
                    <Col className='mb-3'>
                        <Card role="button" onClick={() => this.context.setContent(<ColaboradorList />)}>
                            <Card.Body className='text-center'><FontAwesomeIcon icon={faUsers} size="4x" /><br />Colaboradores</Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default AdminPage;
