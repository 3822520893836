import { Component } from "react";
import AppContext from "../context/AppContext";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class ColaboradorForm extends Component {

    static contextType = AppContext;

    state = {
        ...this.props.data,
        municipios: []
    }

    componentDidMount(){
        this.context.fetchApi("GET", "/municipios/options").then(({data}) => this.setState({municipios: data}));
    }

    submit(event) {
        event.preventDefault();
        const {id, nome, cpf, cargo, telefone, email, MunicipioId} = this.state;
        this.props.onSave({id, nome, cpf, cargo, telefone, email, MunicipioId});
    }

    render() {
        return <Container>
            <Form onSubmit={e => this.submit(e)}>
                <Row>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Nome</b></Form.Label>
                            <Form.Control required value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>CPF</b></Form.Label>
                            <Form.Control required value={this.state.cpf} onChange={e => this.setState({ cpf: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Cargo</b></Form.Label>
                            <Form.Control required value={this.state.cargo} onChange={e => this.setState({ cargo: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Município</b></Form.Label>
                            <Form.Select required value={this.state.MunicipioId} onChange={e => this.setState({ MunicipioId: e.target.value })}>
                                <option></option>
                                {this.state.municipios.map(({value, label}, key) => <option key={key} value={value}>{label}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Telefone</b></Form.Label>
                            <Form.Control required value={this.state.telefone} onChange={e => this.setState({ telefone: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col lg={8} className="mb-1">
                        <Form.Group>
                            <Form.Label className="required"><b>Email</b></Form.Label>
                            <Form.Control required type="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                        </Form.Group>
                    </Col>
                    <Col md={12} className="d-flex justify-content-end mt-2">
                        <Button variant="secondary" onClick={() => this.props.onCancel()}><FontAwesomeIcon icon={faTimes} /> Cancelar</Button>
                        <Button variant="success" className="ms-2" type="submit"><FontAwesomeIcon icon={faCheck} /> Gravar</Button>
                    </Col>
                </Row>
            </Form>
        </Container >;
    }
}

export default ColaboradorForm;
