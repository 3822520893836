import { Component, createRef } from "react";
import addPhoto from "../images/addphoto.jpg";
import EXIF from "exif-js";
import moment from "moment";

class ImageEditor extends Component {

    inputRef = createRef();

    state = {
        hover: false,
        url: null
    }


    click() {
        this.inputRef.current.click();
    }

    componentDidMount() {
        if (this.props.file) {
            this.setState({ url: URL.createObjectURL(this.props.file) });
        }
    }

    componentWillUnmount() {
        if (this.state.url) {
            URL.revokeObjectURL(this.state.url);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.file !== this.props.file) {
            URL.revokeObjectURL(this.state.url)
            this.setState({ url: URL.createObjectURL(this.props.file) });
        }
    }

    change(event) {

        const arquivo = event.target.files[0];
        const { onChange } = this.props

        if (arquivo) {

            EXIF.getData(arquivo, function () {
                const exifTags = EXIF.getAllTags(this);

                const { GPSLatitude, GPSLatitudeRef, GPSLongitude, GPSLongitudeRef, DateTimeOriginal } = exifTags;

                const data = {arquivo, titulo: arquivo.name};

                if (GPSLatitude && GPSLongitude) {
                    // Converte para decimal
                    const lat =
                        GPSLatitude[0] +
                        GPSLatitude[1] / 60 +
                        GPSLatitude[2] / 3600;
                    const lng =
                        GPSLongitude[0] +
                        GPSLongitude[1] / 60 +
                        GPSLongitude[2] / 3600;

                    // Ajusta para N/S e E/W
                    data.latitude = GPSLatitudeRef === 'S' ? -lat : lat;
                    data.longitude = GPSLongitudeRef === 'W' ? -lng : lng;
                }

                if (DateTimeOriginal) {
                    const matches = DateTimeOriginal.match(/(\d+):(\d+):(\d+) (\d+):(\d+):(\d+)/d);
                    if (matches.length === 6) {
                        data.datahora = moment(`${matches[1]}-${matches[2]}-${matches[3]} ${matches[4]}:${matches[5]}:${matches[5]}`);
                    } else {
                        data.datahora = moment();
                    }
                } else if (arquivo.lastModified){
                    data.datahora = moment(arquivo.lastModified);
                } else {
                    data.datahora = moment();
                }

                data.datahora = data.datahora.format("YYYY-MM-DDTHH:mm");

                onChange(data);

            });
        }
    }

    render() {

        const { url } = this.state;

        return <div
            onClick={() => this.props.onChange && this.click()}
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            className="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
            role="button"
        >
            <input type="file" accept="image/*" className="d-none" onChange={event => this.change(event)} ref={this.inputRef} />
            <img
                alt="Clique para adicionar ou alterar a imagem"
                src={url ?? addPhoto}
                style={{ width: "100%", height: "100%", objectFit: 'contain'}}
            />
            <div style={{ position: 'absolute', color: "red", backgroundColor: 'yellow', display: this.state.hover ? 'block' : 'none' }}>
                Clique para adicionar ou alterar a imagem
            </div>
        </div>;
    }
}

export default ImageEditor;