import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

class Backdrop extends Component {

    render() {
        return <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            display: this.props.show? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100000
        }}>
            <FontAwesomeIcon icon={faSpinner} spin size="5x"/>
        </div>;
    }
}

export default Backdrop;