import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

class MenuLeft extends Component {

    render() {
        return <div id="menu-left" className={`bg-dark text-light d-flex flex-column d-print-none ${ this.props.open ? "" : "contract" }`}>
            <div className="d-flex menu-label justify-content-between m-2 menu-title" style={{ overflow: "hidden", height: 24, visibility: this.props.open ? 'visible' : 'hidden' }}>Menu Principal</div>
            {this.props.items.filter(item => item.enabled()).map((item, key) => 
                <div key={key} onClick={() => item.onClick()} role="button" className="d-flex justify-content-between m-2 text-nowrap overflow-hidden" style={{ height: 24, ...item.style }}>
                    <FontAwesomeIcon icon={item.icon} />
                    <span className={this.props.open ? 'menu-label' : 'd-none'}>&nbsp;{item.title}</span>
                </div>
            )}
        </div>;
    }
}

export default MenuLeft;