import { Component } from "react";
import MaterialForm from "./MaterialForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBox } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContext";
import Datatable from "../components/Datatable";
import qs from 'qs';

class MaterialList extends Component {

    static contextType = AppContext;

    doAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.fetchApi("GET", `/materiais/${action.id}`).then(({data}) =>
                    this.context.openModal("Editar Material", <MaterialForm data={data} onSave={data => this.edit(data, callback)} onCancel={() => this.context.closeModal()}/>)
                )
                break;
            case "Duplicar":
                this.context.fetchApi("GET", `/materiais/${action.id}`).then(({data}) =>
                    this.context.openModal("Duplicar Material", <MaterialForm data={data} onSave={data => this.add({...data, id: null}, callback)} onCancel={() => this.context.closeModal()}/>)
                )
                break;
            case "Excluir":
                if (window.confirm(`Deseja realmente excluir o item com ID ${action.id}?`))
                    this.context.fetchApi("DELETE", `/materiais/${action.id}`).finally(callback);
                break;
            default:
                break;
        }
    }

    doRequest(params, callback){
        const query = qs.stringify(params);
        this.context.fetchApi("GET", `/materiais?${query}`).then(({data}) => callback(data));
    }

    add(data, callback){
        this.context.fetchApi("POST", `/materiais`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    edit(data, callback){
        this.context.fetchApi("PUT", `/materiais/${data.id}`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    openAddForm(callback){
        this.context.openModal("Adicionar Material", <MaterialForm data={{}} onSave={data => this.add(data, callback)} onCancel={() => this.context.closeModal()}/>);
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faBox} /> Materiais</div>}
            filters={[
                { name: "nome", label: "Material"},
                { name: "descricao", label: "Descrição"}
            ]}
            onRequest={(params, callback) => this.doRequest(params, callback)}
            onAction={(action, callback) => this.doAction(action, callback)}
            onOpenAddForm={callback => this.openAddForm(callback)} />;
    }
}

export default MaterialList;
