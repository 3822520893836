import { Component } from 'react';
import { Container } from 'react-bootstrap';
import MenuTop from './components/MenuTop';
import MenuLeft from './components/MenuLeft';
import AppContext, { createAppContext } from './context/AppContext';
import AppModal from './components/AppModal';
import Home from './pages/Home';
import LoginForm from './pages/LoginForm';
import { faCogs, faFileAlt, faHome, faSignOut } from '@fortawesome/free-solid-svg-icons';
import AppToasts from './components/AppToasts';
import Backdrop from './components/Backdrop';
import AdminPage from './pages/AdminPage';
import FormularioList from './pages/FormularioList';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = createAppContext(() => this.state, (state, callback) => this.setState(state, callback));
    }

    componentDidMount(){
        this.state.setContent(<Home />);
    }

    openSignInForm(){
        this.state.openModal("Efetuar Logon", <LoginForm onSave={credentials => this.signIn(credentials)} />);
    }

    signIn(credentials) {
        this.state.signIn(credentials)
            .then(() => this.state.closeModal())
            .then(() => this.state.setContent(<Home />))
    }

    signOut() {
        this.state.signOut()
            .then(() => this.state.closeModal())
            .then(() => this.state.setContent(<Home />));
    }

    closeModal(){
        this.state.closeModal();
    }

    toggleMenu(){
        this.state.toggleMenu();
    }

    closeToast(toast){
        this.state.closeToast(toast);
    }

    render() {

        const menuItems = [
            {
                title: "Página Inicial",
                icon: faHome,
                onClick: () => this.state.setContent(<Home />),
                enabled: () => true
            },{
                title: "Formulários",
                icon: faFileAlt,
                onClick: () => this.state.setContent(<FormularioList />),
                enabled: () => this.state.checkPermission("Listar Formulários")
            },{
                title: "Administração",
                icon: faCogs,
                onClick: () => this.state.setContent(<AdminPage />),
                enabled: () => this.state.checkPermission("Administrar Sistema")

            },{
                title: "Sair",
                icon: faSignOut,
                style: {color: "red", marginTop: "auto"},
                onClick: () => this.signOut(),
                enabled: () => !!this.state.usuario
            }
        ]

        return <AppContext.Provider value={this.state}>
            <Container fluid className='h-100 d-flex flex-column m-0 p-0 overflow-auto print-visible app-content'>
                <MenuTop
                    usuario={this.state.usuario}
                    onSignIn={credentials => this.signIn(credentials)}
                    onSignOut={() => this.signOut()}
                    onToggleMenu={() => this.toggleMenu()}
                    onOpenSignInForm={() => this.openSignInForm()}
                />
                <div className='flex-fill d-flex overflow-auto print-visible'>
                    <MenuLeft open={this.state.showMenu} items={menuItems}/>
                    <div className='flex-fill overflow-auto print-visible bg-light'>
                        {this.state.content}
                    </div>
                </div>
            </Container>
            <AppModal
                show={this.state.showModal}
                onHide={() => this.closeModal()}
                title={this.state.modalTitle}
                size={this.state.modalSize}>
                {this.state.modalContent}
            </AppModal>
            <AppToasts toasts={this.state.toasts} onClose={toast => this.closeToast(toast)} />
            <Backdrop show={this.state.loading} />
        </AppContext.Provider >
    }

}

export default App;

