import { Component } from "react";
import QuestionarioForm from "./QuestionarioForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes, faQuestion } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContext";
import Datatable from "../components/Datatable";
import qs from 'qs';

class QuestionarioList extends Component {

    static contextType = AppContext;

    doAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.fetchApi("GET", `/questionarios/${action.id}`).then(({data}) =>
                    this.context.openModal("Editar Questionário", <QuestionarioForm data={data} onSave={data => this.edit(data, callback)} onCancel={() => this.context.closeModal()}/>)
                )
                break;
            case "Duplicar":
                this.context.fetchApi("GET", `/questionarios/${action.id}`).then(({data}) =>
                    this.context.openModal("Duplicar Questionário", <QuestionarioForm data={data} onSave={data => this.add({...data, id: null}, callback)} onCancel={() => this.context.closeModal()}/>)
                )
                break;
            case "Excluir":
                if (window.confirm(`Deseja realmente excluir o item com ID ${action.id}?`))
                    this.context.fetchApi("DELETE", `/questionarios/${action.id}`).finally(callback);
                break;
            default:
                break;
        }
    }

    doRequest(params, callback){
        const query = qs.stringify(params);
        this.context.fetchApi("GET", `/questionarios?${query}`).then(({data}) => callback(data));
    }

    add(data, callback){
        this.context.fetchApi("POST", `/questionarios`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    edit(data, callback){
        this.context.fetchApi("PUT", `/questionarios/${data.id}`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    openAddForm(callback){
        this.context.openModal("Adicionar Questionário", <QuestionarioForm data={{}} onSave={data => this.add(data, callback)} onCancel={() => this.context.closeModal()}/>);
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faQuestion} /> Questionários</div>}
            filters={[
                { name: "nome", label: "Pergunta"},
                { name: "divisao", label: "Divisão"},
                { name: "tipo", label: "Tipo" },
            ]}
            onRequest={(params, callback) => this.doRequest(params, callback)}
            onAction={(action, callback) => this.doAction(action, callback)}
            onOpenAddForm={callback => this.openAddForm(callback)} />;
    }
}

export default QuestionarioList;
