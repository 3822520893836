import { Component } from "react";
import ColaboradorForm from "./ColaboradorForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContext";
import Datatable from "../components/Datatable";
import qs from 'qs';

class ColaboradorList extends Component {

    static contextType = AppContext;

    doAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.fetchApi("GET", `/colaboradores/${action.id}`).then(({ data }) =>
                    this.context.openModal("Editar Colaborador", <ColaboradorForm data={data} onSave={data => this.edit(data, callback)} onCancel={() => this.context.closeModal()} />, "lg")
                )
                break;
            case "Bloquear":
                this.block(action.id, callback);
                break;
            case "Desbloquear":
                this.unblock(action.id, callback);
                break;
            case "Resetar":
                this.reset(action.id, callback);
                break;
            case "Excluir":
                if (window.confirm(`Deseja realmente excluir o item com ID ${action.id}?`))
                    this.context.fetchApi("DELETE", `/colaboradores/${action.id}`).finally(callback);
                break;
            default:
                break;
        }
    }

    doRequest(params, callback) {
        const query = qs.stringify(params);
        this.context.fetchApi("GET", `/colaboradores?${query}`).then(({ data }) => callback(data));
    }

    add(data, callback) {
        this.context.fetchApi("POST", `/colaboradores`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    edit(data, callback) {
        this.context.fetchApi("PUT", `/colaboradores/${data.id}`, data)
            .then(() => this.context.closeModal())
            .then(callback);
    }

    block(id, callback) {
        this.context.fetchApi("PATCH", `/colaboradores/${id}/block`)
            .then(callback);
    }

    unblock(id, callback) {
        this.context.fetchApi("PATCH", `/colaboradores/${id}/unblock`)
            .then(callback);
    }

    reset(id, callback) {
        this.context.fetchApi("PATCH", `/colaboradores/${id}/reset`)
            .then(() => this.context.addToast({ header: "Sucesso", body: "Senha resetada com sucesso." }))
            .then(callback);
    }

    openAddForm(callback) {
        this.context.openModal("Adicionar Colaborador", <ColaboradorForm data={{}} onSave={data => this.add(data, callback)} onCancel={() => this.context.closeModal()} />, "lg");
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faUsers} /> Colaboradores</div>}
            filters={[
                { name: "nome", label: "Colaborador" },
                { name: "Municipio.nome", label: "Município" },
                { name: "cargo", label: "Cargo" },
            ]}
            onRequest={(params, callback) => this.doRequest(params, callback)}
            onAction={(action, callback) => this.doAction(action, callback)}
            onOpenAddForm={callback => this.openAddForm(callback)} />;
    }
}

export default ColaboradorList;
