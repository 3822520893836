import { Component } from "react";
import FormularioForm from "./FormularioForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../context/AppContext";
import Datatable from "../components/Datatable";
import qs from 'qs';
import FormularioPrint from "./FormularioPrint";

class FormularioList extends Component {

    static contextType = AppContext;

    doAction(action, callback) {
        switch (action.name) {
            case "Editar":
                this.context.fetchApi("GET", `/formularios/${action.id}`).then(({ data }) =>
                    this.context.setContent(<FormularioForm data={data} onSave={data => this.edit(data)} onCancel={() => this.context.setContent(<FormularioList />)} />)
                )
                break;
            case "Imprimir":
                this.context.fetchApi("GET", `/formularios/${action.id}/print`).then(({ data }) =>
                    this.context.setContent(<FormularioPrint data={data} onCancel={() => this.context.setContent(<FormularioList />)} />)
                )
                break;
            case "Excluir":
                if (window.confirm(`Deseja realmente excluir o item com ID ${action.id}?`))
                    this.context.fetchApi("DELETE", `/formularios/${action.id}`).finally(callback);
                break;
            default:
                break;
        }
    }

    doRequest(params, callback) {
        const query = qs.stringify(params);
        this.context.fetchApi("GET", `/formularios?${query}`).then(({ data }) => callback(data));
    }

    appendFormData(formData, data, parentKey = '') {
        if (data === null || data === undefined) {
            return formData;
        } else if (data instanceof File) {
            formData.append(parentKey, data);
        } else if (Array.isArray(data)) {
            data.forEach((value, index) => {
                this.appendFormData(formData, value, `${parentKey}[${index}]`);
            });
        } else if (data && typeof data === 'object') {
            Object.entries(data).forEach(([key, value]) => {
                const fieldName = parentKey ? ((value instanceof File) ? `File${parentKey}` : `${parentKey}[${key}]`) : key;
                this.appendFormData(formData, value, fieldName);
            });
        } else {
            formData.append(parentKey, data);
        }

        return formData;
    }

    add(data) {
        console.log(data);
        const formData = this.appendFormData(new FormData(), data);
        this.context.fetchApi("POST", `/formularios`, formData)
            .then(() => this.context.setContent(<FormularioList />));
    }

    edit(data) {
        this.context.fetchApi("PUT", `/formularios/${data.id}`, this.appendFormData(new FormData(), data))
            .then(() => this.context.setContent(<FormularioList />));
    }

    openAddForm() {
        this.context.setContent(<FormularioForm data={{}} onSave={data => this.add(data)} onCancel={() => this.context.setContent(<FormularioList />)} />)
    }

    render() {
        return <Datatable
            title={<div><FontAwesomeIcon icon={faFileAlt} /> Formulários de Solicitações</div>}
            filters={[
                { name: "id", label: "Número" },
                { name: "Cobrade.nome", label: "COBRADE" },
                { name: "Municipio.nome", label: "Município" },
                { name: "status", label: "Status", options: ["Solicitado", "Reconhecido", "Não Reconhecido", "Publicado", "Entregue", "Finalizado", "Cancelado"] },
                { name: "datahora_ocorrencia", label: "Ocorrência (Início)", op: "gte", type: "datetime-local" },
                { name: "datahora_ocorrencia", label: "Ocorrência (Fim)", op: "lte", type: "datetime-local" },
                { name: "datahora_solicitacao", label: "Solicitação (Início)", op: "gte", type: "datetime-local" },
                { name: "datahora_solicitacao", label: "Solicitação (Fim)", op: "lte", type: "datetime-local" },
            ]}
            onRequest={(params, callback) => this.doRequest(params, callback)}
            onAction={(action, callback) => this.doAction(action, callback)}
            onOpenAddForm={callback => this.openAddForm(callback)} />;
    }
}

export default FormularioList;
