import { Component } from "react";
import { Modal } from "react-bootstrap";

class AppModal extends Component {

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide} size={this.props.size}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.children}
            </Modal.Body>
        </Modal>;
    }
}

export default AppModal;