import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card, Col, Container, Form, Pagination, Row, Table } from "react-bootstrap";

const defaultFilters = filters => (filters ?? []).map(param => ({
    value: "",
    op: "like",
    type: "text",
    label: param.name ?? "&nbsp;",
    disabled: false,
    ...param,
}));

class Datatable extends React.Component {

    timeout = null;

    state = {
        data: {
            headers: [],
            rows: [],
            count: 0
        },
        order: "",
        dir: "",
        limit: 10,
        offset: 0,
        filters: defaultFilters(this.props.filters),
        open: true
    }

    update() {

        const { limit, offset, order, dir } = this.state;

        const filters = this.state.filters
            .filter(filter => filter.value !== "" && !filter.disabled)
            .map(filter => ({ name: filter.name, op: filter.op, value: filter.op === "like" ? `%${filter.value}%` : filter.value }));

        this.props.onRequest({ filters, limit, offset, order, dir }, data => {
            const { headers = [], rows = [], count = 0 } = data;
            this.setState({ data: { headers, rows, count } });
        });
    }

    lastPage() {
        this.setState(state => ({ offset: Math.floor((state.data.count - 1) / state.limit) * state.limit }), () => this.update());
    }

    nextPage() {
        this.setState(state => ({ offset: state.offset + state.limit }), () => this.update());
    }

    prevPage() {
        this.setState(state => ({ offset: state.offset - state.limit }), () => this.update());
    }

    firstPage() {
        this.setState({ offset: 0 }, () => this.update());
    }

    componentDidMount() {
        this.update();
    }

    search(event) {
        event.preventDefault();
        this.setState({ offset: 0, open: false }, () => this.update());
        return false;
    }

    render() {

        const page = (this.state.limit ? this.state.offset / this.state.limit : 0) + 1;
        const firstPage = !this.state.offset;
        const lastPage = !this.state.limit || ((this.state.data.count - this.state.limit) <= this.state.offset);
        const maxPage = this.state.data.count ? Math.ceil(this.state.data.count / (this.state.limit ? this.state.limit : this.state.data.count)) : 1;

        return <Container className="mt-2">
            {(this.props.title || this.props.onOpenAddForm) &&
                <h2 className="mb-3 text-center d-flex justify-content-between align-items-center">
                    {this.props.title}
                    {this.props.onOpenAddForm && <Button variant="success" onClick={() => this.props.onOpenAddForm(() => this.update())}>
                        <FontAwesomeIcon icon={Icons.faPlus} /> Adicionar
                    </Button>}
                </h2>}
            {this.state.filters &&
                <Card className="mb-2">
                    <Card.Header><Card.Title><FontAwesomeIcon icon={Icons.faFilter} /> Filtro de Pesquisa</Card.Title></Card.Header>
                    <Card.Body>
                        <Form style={{ fontSize: "8pt" }} onSubmit={event => this.search(event)} onReset={() => this.setState({ filters: defaultFilters(this.props.filters) })}>
                            <Row>
                                {this.state.filters.map((filter, key) =>
                                    <Col key={key} className={filter.type === "hidden" ? "d-none" : "mb-2"} md={4}>
                                        <Form.Label className="m-0"><b className="text-small">{filter.label}</b></Form.Label>
                                        {(filter.options &&
                                            <Form.Select size="sm" disabled={filter.disabled} value={filter.value} onChange={e => this.setState(state => ({ filters: state.filters.map(f => f === filter ? { ...filter, value: e.target.value } : f) }))}>
                                                <option></option>
                                                {filter.options.map((option, key) => <option key={key} value={option.value ?? option}>{option.label ?? option}</option>)}
                                            </Form.Select>
                                        ) || <Form.Control size="sm" disabled={filter.disabled} type={filter.type} value={filter.value} onChange={e => this.setState(state => ({ filters: state.filters.map(f => f === filter ? { ...filter, value: e.target.value } : f) }))} />}
                                    </Col>
                                )}
                                <Col className="mb-2 d-flex align-items-end">
                                    <Button size="sm" type="reset" className="form-control" variant="secondary">
                                        <FontAwesomeIcon icon={Icons.faEraser} /> Limpar
                                    </Button>
                                </Col>
                                <Col className="mb-2 d-flex align-items-end">
                                    <Button size="sm" type="submit" className="form-control">
                                        <FontAwesomeIcon icon={Icons.faSearch} /> Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            }
            <Card className="app-card mb-3">
                <Card.Body>
                    <Table size="sm" responsive striped className="app-table small">
                        <thead>
                            <tr>
                                {this.state.data.headers.map((header, key) =>
                                    <th className="text-nowrap" key={key} role="button" onClick={() => this.setState(state => ({ order: state.order !== header.order ? header.order : (!state.dir || state.dir === "ASC" ? header.order : ""), dir: state.order !== header.order ? "ASC" : (state.dir === "ASC" ? "DESC" : (state.dir === "DESC" ? "" : "ASC")) }), () => this.update())}>
                                        {header.title}
                                        &nbsp;
                                        {header.order === this.state.order ?
                                            this.state.dir === "ASC" ?
                                                <FontAwesomeIcon icon={Icons.faSortAlphaAsc} /> :
                                                <FontAwesomeIcon icon={Icons.faSortAlphaDesc} />
                                            :
                                            null
                                        }
                                    </th>)}
                                {this.props.onAction &&
                                    <th className="text-center" style={{ width: '1%' }}>
                                        Ações
                                    </th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.rows.length > 0 ?
                                this.state.data.rows.map((row, key) => <tr key={key}>
                                    {row.values.map((value, key) =>
                                        <td key={key} style={{ verticalAlign: "middle" }}>
                                            {value}
                                        </td>)}
                                    {this.props.onAction &&
                                        <td className="text-center text-nowrap" style={{ width: '1%', verticalAlign: "middle" }}>
                                            {row.actions.length > 0 ? row.actions.map((action, key) =>
                                                <Button size="sm" title={action.name} key={key} className="mx-1" variant={action.variant} onClick={() => this.props.onAction(action, () => this.update())}>
                                                    <FontAwesomeIcon icon={Icons[action.icon]} />
                                                </Button>
                                            ) : "Sem Ações"}
                                        </td>}
                                </tr>) :
                                <tr>
                                    <td colSpan={this.state.data.headers.length + (this.props.onAction ? 1 : 0)} className="text-center">
                                        Nenhum resultado encontrado.
                                    </td>
                                </tr>}
                        </tbody>
                        <tfoot>
                            <tr className="app-table-tools">
                                <td colSpan={this.state.data.headers.length + 1}>
                                    <Form onSubmit={event => this.search(event)} className="d-flex flex-md-row flex-column justify-content-end align-items-center">
                                        Total: {this.state.data.count} registro(s).
                                        <Form.Select size="sm" className="m-1 w-auto" style={{ minWidth: 180 }} value={this.state.limit} onChange={e => this.setState({ limit: parseInt(e.target.value), offset: 0 }, () => this.update())}>
                                            <option value={10}>10 registros</option>
                                            <option value={20}>20 registros</option>
                                            <option value={50}>50 registros</option>
                                            <option value={100}>100 registros</option>
                                            <option value={0}>Todos os registros</option>
                                        </Form.Select>
                                        <Pagination size="sm" className="m-1 w-auto">
                                            <Pagination.Item className={firstPage ? "disabled" : ""}><FontAwesomeIcon icon={Icons.faBackwardFast} onClick={() => this.firstPage()} /></Pagination.Item>
                                            <Pagination.Item className={firstPage ? "disabled" : ""}><FontAwesomeIcon icon={Icons.faBackwardStep} onClick={() => this.prevPage()} /></Pagination.Item>
                                            <Pagination.Item className="disabled flex-fill text-center text-nowrap">Página {page}/{maxPage}</Pagination.Item>
                                            <Pagination.Item className={lastPage ? "disabled" : ""}><FontAwesomeIcon icon={Icons.faForwardStep} onClick={() => this.nextPage()} /></Pagination.Item>
                                            <Pagination.Item className={lastPage ? "disabled" : ""}><FontAwesomeIcon icon={Icons.faForwardFast} onClick={() => this.lastPage()} /></Pagination.Item>
                                        </Pagination>
                                    </Form>
                                </td>
                            </tr>
                        </tfoot>
                    </Table>
                    <small><i>N/A: Não Aplicável</i></small>
                </Card.Body>
            </Card>
        </Container>;
    }
}

export default Datatable;
