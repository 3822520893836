import { createContext } from "react";
import axios from 'axios';
import * as jwt from 'jwt-decode';
import LoginForm from "../pages/LoginForm";

const AppContext = createContext();

export const createAppContext = (getContext, setContext) => ({
    usuario: null,
    loading: false,
    token: "",
    checkPermission: (permission) => !permission || getContext().usuario?.Vinculos?.find(v => v.Perfil?.Permissoes?.find(p => p.nome === permission)) !== undefined,
    showModal: false,
    modalContent: null,
    modalSize: "md",
    modalTitle: "",
    openModal: (modalTitle, modalContent, modalSize) => new Promise(resolve => setContext({ showModal: true, modalTitle, modalContent, modalSize }, resolve)),
    closeModal: () => new Promise(resolve => setContext({ showModal: false }, resolve)),
    showMenu: true,
    content: null,
    setContent: content => new Promise(resolve => setContext({ content }, resolve)),
    toggleMenu: () => new Promise(resolve => setContext(state => ({ showMenu: !state.showMenu }), resolve)),
    fetchApi: (method, url, data, options = {}) => new Promise(async (resolve, reject) => {

        const timeout = setTimeout(() => setContext({ loading: true }), 500);

        const token = getContext().token;

        const headers = {
            "Authorization": token ? `Bearer ${token}` : ``
        }

        if (data instanceof FormData) headers["Content-Type"] = "multipart/form-data";

        axios.request({
            method,
            url: process.env.REACT_APP_BACKEND_URL + url,
            data,
            headers,
            ...options
        })
            .then(resolve)
            .catch(error => {

                const context = getContext();

                if (error.response?.status === 401) {
                    const signInAndResend = credentials => context.signIn(credentials)
                        .then(() => context.closeModal())
                        .then(() => context.fetchApi(method, url, data))
                        .then(resolve)
                        .catch(reject)

                    context.addToast({ header: "Autenticação necessária", body: "Por favor, realize a entrada." });
                    context.openModal("Autenticação", <LoginForm onSave={credentials => signInAndResend(credentials)} />)

                } else {
                    context.addToast({ header: "Erro na requisição", body: error.response?.data.message ?? error.message });
                };
            })
            .finally(() => {
                clearTimeout(timeout);
                setContext({ loading: false });
            })
    }),
    fetchAuth: (method, url, data) => axios.request({
        method,
        url: process.env.REACT_APP_AUTH_URL + url,
        data
    }),
    signIn: (credentials) => new Promise(async resolve => {

        const { esfera } = credentials;

        const errorHandler = message => {
            getContext().addToast({ header: "Falha de Autenticação", body: message });
        };

        const responseHandler = response => {
            const { token } = response.data;
            const usuario = jwt.jwtDecode(token);
            setContext({ usuario, token }, resolve);
        }

        const authHandler = () => {
            return (esfera === "Defesa Civil Estadual") ?
                getContext().fetchAuth("POST", "/login", credentials) :
                getContext().fetchApi("POST", "/colaboradores/signin", credentials)
        };

        authHandler()
            .then(responseHandler)
            .catch(error => errorHandler(error.response.data?.error ?? error.message));
    }),
    signOut: () => new Promise(resolve => setContext({ usuario: null, token: "" }, resolve)),
    toasts: [],
    addToast: toast => new Promise(resolve => setContext(({ toasts }) => ({ toasts: [...toasts, toast] }), resolve)),
    closeToast: toast => new Promise(resolve => setContext(({ toasts }) => ({ toasts: toasts.filter(t => t !== toast) }), resolve))
});

export default AppContext;