import { faCogs, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';

class LoginForm extends Component {

    state = {
        login: "",
        password: "",
        esfera: ""
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.onSave(this.state);
    };

    render() {
        return (
            <Form onSubmit={e => this.onSubmit(e)}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Esfera</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCogs} /></InputGroup.Text>
                                <Form.Select required className='regular'
                                    value={this.state.esfera}
                                    onChange={e => this.setState({ esfera: e.target.value })}
                                >
                                    <option></option>
                                    <option>Defesa Civil Municipal</option>
                                    <option>Defesa Civil Estadual</option>

                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>Login/CPF</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                                <Form.Control
                                    required
                                    value={this.state.login}
                                    onChange={e => this.setState({ login: e.target.value })}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Label>Senha</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faKey} /></InputGroup.Text>
                                <Form.Control
                                    type="password"
                                    required
                                    value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                            Entrar
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default LoginForm;
