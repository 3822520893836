import { Component, createRef } from "react";
import { Button } from "react-bootstrap";

class FileUploader extends Component {

    inputRef = createRef();

    state = {
        dragging: false
    }

    dragOver(event){
        event.preventDefault();
        this.setState({dragging: true});
    }

    dragLeave(){
        this.setState({dragging: false});
    }

    drop(event){
        event.preventDefault();
        event.stopPropagation();
        this.setState({dragging: false});

        const arquivos = Array.from(event.dataTransfer.files);
        this.props.onChange(arquivos);
    }

    click() {
        this.inputRef.current.click();
    }

    change(event) {
        const arquivos = Array.from(event.target.files);
        this.props.onChange(arquivos);
    }

    render() {
        return <>
            <input multiple type="file" className="d-none" onChange={event => this.change(event)} ref={this.inputRef} />
            <Button
                variant={this.state.dragging? "success" : "info"}
                style={{ 
                    minHeight: "120px", 
                    width: "100%", 
                    border: "dashed 5px black", 
                 }}
                onClick={() => this.click()}
                onDragOver={event => this.dragOver(event)}
                onDragLeave={() => this.dragLeave()}
                onDrop={event => this.drop(event)}
            >
                {this.state.dragging? "Solte os arquivos aqui" : "Clique ou arraste arquivos para adicioná-los como anexos"}
            </Button>
        </>;
    }
}

export default FileUploader;